import { requireImg } from "@/tools/modules/tools";

// 设置Logo 以及 Title
export  function setTitleAndLogo() {
  const params = {
    "www.yihui.sd.cn": {
      logo: requireImg('images/logo/yihui.sd.cn.png'),
      // setPageTitle: () => document.title = "翼绘AI · SD - 云端Stable Diffusion",
    },
    "yihui.sd.cn": {
      logo: requireImg('images/logo/yihui.sd.cn.png'),
      // setPageTitle: () => document.title = "翼绘AI · SD - 云端Stable Diffusion",
    },
    "test.yihui.sd.cn": {
      logo: requireImg('images/logo/yihui.sd.cn.png'),
      // setPageTitle: () => document.title = "翼绘AI · SD - 云端Stable Diffusion",
    },
    'other': {
      logo: requireImg('images/logo/logo.png'),
      setPageTitle: () => { },
    }
  };
  let hostname = location.hostname;
  if (params[hostname]) {
    // params[hostname]['setPageTitle']();
    return params[hostname]['logo'];
  } else {
    params['other']['setPageTitle']();
    return params['other']['logo'];
  }
}

export function hrefShowMajor(){
  const hrefArr = ["www.yihui.sd.cn", "yihui.sd.cn", "test.yihui.sd.cn"];
  let hostname = location.hostname;
  return hrefArr.indexOf(hostname) !== -1
}
