import { batchImgStore } from "@/api";
import { progressTask } from "@/services/api";
import { useCreatePlanStore, useFormStore, useImage2Store,useGenerateInfoStore, useCancelGenerateInfoStore,useBatchSizeStore } from "@/zustand";
import { message } from 'antd';
import axios from "axios";
import { useState } from 'react';

// 开始异步生成图片
let asyncGetImgTimer;
let countBegin = false;
export function getProgressTask(task_id) {
  if (asyncGetImgTimer) clearTimeout(asyncGetImgTimer);
  const setCreatePlan = useCreatePlanStore.setState;
  const setGenerating = useFormStore.setState;
  const setImages = useImage2Store.setState;
  const setGenerateInfo = useGenerateInfoStore.setState;
  const { cancelGenerateInfo } = useCancelGenerateInfoStore.getState();
  const { getDotNum } = useBatchSizeStore.getState();
  async function beginTask() {
    clearTimeout(asyncGetImgTimer);
    try {
      const res = await progressTask({ task_id },{cancelToken:cancelGenerateInfo.token});
      if(res?.task_id){ setCreatePlan({ createPlan: res || {} }); };
      if (res?.task_status == 2) {
        countBegin = false;
        setGenerating({ generating: false });
        setCreatePlan({ createPlan: {} }); 
        setGenerateInfo({ generateInfo: res }); 
        getDotNum();
        setImages({ images: res?.data?.images.map((item) => {
          return `data:image/png;base64,${item}`;
        }) });
        return clearTimeout(asyncGetImgTimer);
      } else if (res?.task_status == 3) {
        countBegin = false;
        setGenerating({ generating: false });
        setCreatePlan({ createPlan: {} }); 
        return message.warning('生成出错，请重新生成')
      }
      asyncGetImgTimer = setTimeout(() => {
        beginTask();
      }, 400);
    } catch (error) {
      countBegin = false;
      setGenerating({ generating: false });
      setCreatePlan({ createPlan: {} }); 
    }
  };
  if (!countBegin) {
    countBegin = true;
    beginTask();
  };
};
 
// 静态数据，无法响应式 sdModelsHook
export function sdModelsHook() {
  let staticSDModels = {};
  function setStaticSDModels(staticSDModels) {
    staticSDModels = staticSDModels;
  }
  return {
    staticSDModels,
    setStaticSDModels
 }
};

// 上报生成图片信息
export async function sendImageInfo({ imgRes, sdParams, override_settings }) {
  const params = {
    "model": override_settings?.name || "1_CounterfeitV30_v30.safetensors",
    "prompt": sdParams.prompt,
    "n_prompt": sdParams.negative_prompt,
    "sampler": sdParams.sampler_index,
    "cfg_scale": sdParams.cfg_scale,
    "step": sdParams.steps,
    "steps": sdParams.steps,
    "seed": sdParams.seed,
    "Dimensions": 0
  }
  for (let i = 0; i < imgRes.images.length; i++) {
    const item = imgRes.images[i]
    const imageURL = `data:image/png;base64,${item}`;
    if (imgRes.status == 'succeeded') {
      const res = await batchImgStore({
        ...params,
        ai_type: '2',
        image: imageURL,
        id_task: imgRes?.taskId,
      }).catch(err => {});
    }
  }
}

// ADetailer 初始信息
export function initAdetailerDataArr() {
  return [
    {
      "ad_model": "face_yolov9c.pt",
      "ad_tap_enable": true,
      "ad_prompt": "",//// 提示词
      "ad_negative_prompt": "",//// 反向提示词
      "ad_confidence": 0.3, // Detection model confidence threshold 检测模型置信度阈值
      "ad_mask_k_largest": 0, // Mask only the top k largest 仅遮罩前 k 个最大值
      "ad_mask_min_ratio": 0,// Mask min area ratio 掩膜最小面积比
      "ad_mask_max_ratio": 1,// Mask max area ratio 掩膜最大面积比率
      "ad_x_offset": 0, // Mask x(→) offset 掩码 x(→) 偏移量
      "ad_y_offset": 0, // Mask y(↑) offset 掩码 y(↑) 偏移量
      "ad_dilate_erode": 4,// Mask erosion (-) / dilation (+) 掩模腐蚀 (-) / 膨胀 (+)
      "ad_mask_merge_invert": "Merge",// Mask merge mode 蒙版合并模式（None | Merge | Merge and Invert）
      "ad_mask_blur": 4,// Inpaint mask blur 修复蒙版模糊
      "ad_denoising_strength": 0.4,// Inpaint denoising strength 修复去噪强度
      "ad_inpaint_only_masked": true,// Inpaint only masked 仅对遮罩区域进行修复
      "ad_inpaint_only_masked_padding": 32,// Inpaint only masked padding, pixels 仅对遮罩填充区域进行修复，像素
      "ad_use_inpaint_width_height": false,// Use separate width/height 使用单独的宽度/高度
      "ad_inpaint_width": 512,// inpaint width 修复宽度
      "ad_inpaint_height": 512,// inpaint height 修复高度
      "ad_use_steps": false,// Use separate steps 使用单独的步骤
      "ad_steps": 28,// ADetailer steps 细节处理步骤
      "ad_use_cfg_scale": false,// Use separate CFG scale 使用单独的 CFG 比例
      "ad_cfg_scale": 7,// ADetailer CFG scale 细节调整 CFG 比例
      "ad_use_checkpoint": false,// Use separate checkpoint 使用单独的检查点
      "ad_checkpoint": 1, // ADetailer checkpoint 细节检查点
      "ad_use_vae": false,// Use separate VAE 使用单独的变分自编码器
      "ad_vae": "Use same VAE",// ADetailer VAE
      "ad_use_sampler": false,// Use separate sampler 使用单独的采样器
      "ad_sampler": "DPM++ 2M Karras",// ADetailer sampler ADetailer 取样器
      "ad_scheduler": 1,
      "ad_use_noise_multiplier": false,// Use separate noise multiplier 使用单独的噪声乘数
      "ad_noise_multiplier": 1,// Noise multiplier for img2img 图生图噪声倍率
      "ad_use_clip_skip": false,// Use separate CLIP skip 使用单独的 CLIP 跳过
      "ad_clip_skip": 1,// ADetailer CLIP skip ADetailer CLIP 跳过
      "ad_restore_face": false,// Restore faces after ADetailer 在 ADetailer 之后恢复面部
      "ad_controlnet_model": "None",// ControlNet model ControlNet 模型
      "ad_controlnet_module": "None",// ControlNet module ControlNet 模块
      "ad_controlnet_weight": 1,// ControlNet weight ControlNet 权重
      "ad_controlnet_guidance_start": 0,// ControlNet guidance start ControlNet 指导开始
      "ad_controlnet_guidance_end": 1,// ControlNet guidance end 控制网指导结束
      "is_api": true
    },
    {
      "ad_model": "face_yolov9c.pt",
      "ad_tap_enable": true,
      "ad_prompt": "",//// 提示词
      "ad_negative_prompt": "",//// 反向提示词
      "ad_confidence": 0.3, // Detection model confidence threshold 检测模型置信度阈值
      "ad_mask_k_largest": 0, // Mask only the top k largest 仅遮罩前 k 个最大值
      "ad_mask_min_ratio": 0,// Mask min area ratio 掩膜最小面积比
      "ad_mask_max_ratio": 1,// Mask max area ratio 掩膜最大面积比率
      "ad_x_offset": 0, // Mask x(→) offset 掩码 x(→) 偏移量
      "ad_y_offset": 0, // Mask y(↑) offset 掩码 y(↑) 偏移量
      "ad_dilate_erode": 4,// Mask erosion (-) / dilation (+) 掩模腐蚀 (-) / 膨胀 (+)
      "ad_mask_merge_invert": "Merge",// Mask merge mode 蒙版合并模式（None | Merge | Merge and Invert）
      "ad_mask_blur": 4,// Inpaint mask blur 修复蒙版模糊
      "ad_denoising_strength": 0.4,// Inpaint denoising strength 修复去噪强度
      "ad_inpaint_only_masked": true,// Inpaint only masked 仅对遮罩区域进行修复
      "ad_inpaint_only_masked_padding": 32,// Inpaint only masked padding, pixels 仅对遮罩填充区域进行修复，像素
      "ad_use_inpaint_width_height": false,// Use separate width/height 使用单独的宽度/高度
      "ad_inpaint_width": 512,// inpaint width 修复宽度
      "ad_inpaint_height": 512,// inpaint height 修复高度
      "ad_use_steps": false,// Use separate steps 使用单独的步骤
      "ad_steps": 28,// ADetailer steps 细节处理步骤
      "ad_use_cfg_scale": false,// Use separate CFG scale 使用单独的 CFG 比例
      "ad_cfg_scale": 7,// ADetailer CFG scale 细节调整 CFG 比例
      "ad_use_checkpoint": false,// Use separate checkpoint 使用单独的检查点
      "ad_checkpoint": 1, // ADetailer checkpoint 细节检查点
      "ad_use_vae": false,// Use separate VAE 使用单独的变分自编码器
      "ad_vae": "Use same VAE",// ADetailer VAE
      "ad_use_sampler": false,// Use separate sampler 使用单独的采样器
      "ad_sampler": "DPM++ 2M Karras",// ADetailer sampler ADetailer 取样器
      "ad_scheduler": 1,
      "ad_use_noise_multiplier": false,// Use separate noise multiplier 使用单独的噪声乘数
      "ad_noise_multiplier": 1,// Noise multiplier for img2img 图生图噪声倍率
      "ad_use_clip_skip": false,// Use separate CLIP skip 使用单独的 CLIP 跳过
      "ad_clip_skip": 1,// ADetailer CLIP skip ADetailer CLIP 跳过
      "ad_restore_face": false,// Restore faces after ADetailer 在 ADetailer 之后恢复面部
      "ad_controlnet_model": "None",// ControlNet model ControlNet 模型
      "ad_controlnet_module": "None",// ControlNet module ControlNet 模块
      "ad_controlnet_weight": 1,// ControlNet weight ControlNet 权重
      "ad_controlnet_guidance_start": 0,// ControlNet guidance start ControlNet 指导开始
      "ad_controlnet_guidance_end": 1,// ControlNet guidance end 控制网指导结束
      "is_api": true
    }
  ]
};

// LayerDiffuse 初始信息
export function initLayerDiffuseDataArr() {
  return {
    enabled: false,
    model: '(SD1.5) Only Generate Transparent Image (Attention Injection)',
    Weight: 1,
    StopAt: 1,
    Foreground: null,
    Background: null,
    Blending: null,
    ResizeMode: 'Crop and Resize',
    none:false,
    ForegroundPrompt: '',
    BackgroundPrompt: '',
    BlendedPrompt: '',
    // args:[
    //   false, // #是否开启 0
    //   "(SD1.5) Only Generate Transparent Image (Attention Injection)", // #选择模型 1
    //   1, // #Weight 2
    //   1, // #Stop At 3
    //   null,//#Foreground 前景 4
    //   null,//#Background 后景 5
    //   null, // #Blending 6
    //   'Crop and Resize', // #Resize mode ：Just Resize|Crop and Resize|Resize and Fill 7
    //   false, // 暂未知干嘛的，先占位 8
    //   "", // #Foreground Additional Prompt 前景附加提示词 9
    //   "", // #Background Additional Prompt 背景附加提示词 10
    //   "", // #Blended Additional Prompt 融合附加提示词  11
    // ]
  }
};

  