import { create } from 'zustand';
import { FormInstance } from 'antd';
import { StableDiffusionWebUIConfig } from '@/types/config';
import { DefaultStableDiffusionWebUIConfig } from '@/configs';
import { getLoginActiveInfo, getToken, removeToken, setToken } from '@/utils/auth';
import { fetchUserInfo } from '@/api';
import { UserInfoResInter } from '@/types/common/request';
import {
  ConfigParamsState, ExtensionsParamsState, FormInstanceState,
  FormState, Image2ParamsState, LoginState, useADetailerCheckedState,
  useBatchSizeState, useCreatePlanStoreState, useLayerDiffusionCheckedState,
  useSelectorState, useSetSDModelState, PlatformState, YihuiLoginState
} from './types/types';
import { fetchPoints, } from '@/services/api';
import { fetchUserInfo3,fetchUserInfo2 } from '@/services/yihui';
import { initAdetailerDataArr } from '@/hooks/global';


// 平台标识
export const usePlatformStore=create<PlatformState>((set)=>({
  platform:'aigc',
  isAigc:true,
  isYihui:false,
  setPlatform:(platform)=>{
    set({ platform })
    if(platform=='yihui'){
      set({ isAigc:false })
      set({ isYihui:true })
    }
  }
}))

// 图片生成加载...
export const useFormStore = create<FormState>((set) => ({
  generating: false,
  setGenerating: (generating) => set({ generating }),
}));
// 表单this
export const useFormInstanceStore = create<FormInstanceState>((set) => ({
  myFormInstance: null,
  setMyFormInstance: (myFormInstance) => set({ myFormInstance }),
}));

// 扩展部分
export const useExtensionsStore = create<ExtensionsParamsState>((set) => ({
  extensionsParams: {
    ADetailer: { ADetailerDataArr:initAdetailerDataArr() }
  },
  setExtensionsParams: (extensionsParams) => set({ extensionsParams }),
}));

// 配置部分
export const useConfigStore = create<ConfigParamsState>((set) => ({
  config: DefaultStableDiffusionWebUIConfig,
  setConfig: (config) => set({ config }),
}));

// 图片信息部分
export const useImage2Store = create<Image2ParamsState>((set) => ({
  images: [],
  setImages: (images) => set({ images }),
  Image2ImageURL: {
    init_images:'',
    init_img_inpaint:'',
    init_mask_inpaint: '',
  },
  setImage2ImageURL: (Image2ImageURL) => set({ Image2ImageURL }),
}));

// 后期处理图片信息部分
export const useIntegratedStore = create<Image2ParamsState>((set) => ({
  images: [],
  setImages: (images) => set({ images }),
  Image2ImageURL: {
    image:'',
    init_img_inpaint:'',
    init_mask_inpaint: '',
  },
  setImage2ImageURL: (Image2ImageURL) => set({ Image2ImageURL }),
}));

// 后期处理图片信息部分
export const useTiledStore = create<Image2ParamsState>((set) => ({
  images: [],
  setImages: (images) => set({ images }),
  Image2ImageURL: {
    image:'',
    init_img_inpaint:'',
    init_mask_inpaint: '',
  },
  setImage2ImageURL: (Image2ImageURL) => set({ Image2ImageURL }),
}));

// ADetailer 是否启用
export const useADetailerChecked = create<useADetailerCheckedState>((set) => ({
  ADetailerChecked: false,
  setADetailerChecked: (ADetailerChecked) => set({ ADetailerChecked }),
}));

// LayerDiffusion 是否启用
export const useLayerDiffusionChecked = create<useLayerDiffusionCheckedState>((set) => ({
  layerDiffusionChecked: false,
  setLayerDiffusionChecked: (layerDiffusionChecked) => set({ layerDiffusionChecked }),
}));

// 设置回填数据
export const useBackDataStore = create<Record<string,any>>((set) => ({
  backData: {
    paramData:{}
  },
  setBackData: (backData) => set({ backData }),
}));

// 点数部分
export const useBatchSizeStore = create<useBatchSizeState>((set) => ({
  num: 1,
  dotNumInfo: {
    points_deducted: 0,
    dailyLimit: 0,
    account_points: 0,
    public_points: 0,
  },
  changeNum: (num) => set({ num }),
  changeDotNum: (dotNumInfo) => set(dotNumInfo),
  getDotNum: async () => {
    const res:any = await fetchPoints();
    if (res.status) {
      set({
        dotNumInfo:res.data
      })
    }
  }
}));

// 登录弹窗信息
export const useLoginStore = create<LoginState>((set) => ({
  loginVisible: false,
  setLoginVisible: (loginVisible) => set({ loginVisible }),
}));

// 登录用户信息等
export const useSelectorStore = create<useSelectorState>((set) => ({
  authToken: getToken(),
  userInfo: null,
  orgInfo:{},
  setOrgInfo: (orgInfo) => {
    set({ orgInfo });
  },
  setStoreToken: (authToken) => {
    setToken(authToken);
    set({ authToken });
  },
  removeStoreToken: () => {
    removeToken();
    set({ authToken: "", userInfo: null });
  },
  setUserInfo: (userInfo) => {
    set({ userInfo });
  },
  getUserInfo(){
    return new Promise(async (resolve, reject) => {
      const {platform, isYihui, isAigc } =usePlatformStore.getState();
      const { activeTabKey } = useYihuiLoginStore.getState()
      let res: any = {};
      
      if (!isYihui){
        res = await fetchUserInfo();
        if (res?.status) {
          set({ userInfo: res?.data });
        }
      } else if (activeTabKey == '3') { //yihui.sd
        //activeTabKey 1微信登录 2 手机登录 3 翼狐账号登录
        res = await fetchUserInfo3().catch(err => {  set({ authToken: "",userInfo: null }); });;
        if (res.code) {
          // console.log(res);
          let {nickname,avatar}=res?.data
          let info = {
            ...res?.data,
            name:nickname,
            avatar
          }
          set({ userInfo: info });
        } else {
          set({ authToken: "",userInfo: null });
        }
        
      } else { //yihui.sd
        //activeTabKey 1微信登录 2 手机登录 3 翼狐账号登录
        res = await fetchUserInfo2().catch(err => {  set({ authToken: "",userInfo: null }); });
        if (res.code) { 
          // console.log(res);
          let {nickname,avatar}=res?.data
          let info = {
            ...res?.data,
            name:nickname,
            avatar,
          }
          set({ userInfo: info });
        } else {
          set({ authToken: "",userInfo: null });
        }
      }
      resolve(res);
    })
  },
}));

// 设置当前选中的大模型
export const useSetSDModelStore = create<useSetSDModelState>((set) => ({
  sdModel: {},
  setSDModel: (sdModel) => {
    set({ sdModel });
  },
}));

// 当前生成图片进度
export const useCreatePlanStore = create<useCreatePlanStoreState>((set) => ({
  createPlan: {},
  setCreatePlan: (createPlan) => {
    set({ createPlan });
  },
}));

// 设置当前生成信息
export const useGenerateInfoStore = create<any>((set) => ({
  generateInfo: {},
  setCreatePlan: (generateInfo) => {
    set({ generateInfo });
  },
}));

// 设置取消生成信息
export const useCancelGenerateInfoStore = create<any>((set) => ({
  cancelGenerateInfo: {},
  setCancelCreatePlan: (cancelGenerateInfo) => {
    set({ cancelGenerateInfo });
  },
}));

// form 表单信息
export const useFormInfoStore = create<any>((set) => ({
  formInfo: {
    sampler_index: 'Euler a',
    negative_prompt: 'ng_deepnegative_v1_75t,(badhandv4:1.2),EasyNegative,(worst quality:2),',
    steps: '30',
    width: '1024',
    height: '1024',
    cfg_scale: '7',
    batch_size: 1,
    seed: '-1',
    resize_mode: 0,
    mask_alpha: 0,
    mask_blur: '4',
    inpainting_mask_invert: '0',
    inpainting_fill: '1',
    inpaint_full_res: false,
    inpaint_full_res_padding: '32',
    enable_hr: false,
    hrenable: false,
    hr_upscaler:'R-ESRGAN 4x+',
    hr_second_pass_steps:0,
    denoising_strength: '0.75',
    hr_cfg:7,
    hr_scale:1,
    hr_resize_x:0,
    hr_resize_y:0,
  },
  setFormInfo: (formInfo) => {
    set({ formInfo });
  },
}));

// yihui 登录弹窗信息 tab 切换
export const useYihuiLoginStore = create<YihuiLoginState>((set) => ({
  activeTabKey: getLoginActiveInfo().loginActive?getLoginActiveInfo().loginActive:'1',
  setActiveTabKey: (activeTabKey) => set({ activeTabKey }),
}));