import axios, { AxiosHeaders,AxiosRequestConfig  } from 'axios';
import { message } from 'antd';
import { getToken, removeToken } from './auth'
import { BaseResponse } from "@/types/common/request"
import { useLoginStore, useSelectorStore, usePlatformStore } from '@/zustand';
const DEFAULT_ERROR_CODE = 10000;

// let HREF_API = import.meta.env.VITE_APP_BASE_API
const instance = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_API,
});

const request = async (config: AxiosRequestConfig) => {
  const { data } = await instance(config)
  return data
}

let modalRef;
export async function login(params: { username: string, password: string }): Promise<any> {
  const { username, password } = params;

  return instance ('/login/doLoginByPwd', {
    method: 'POST', data: {
      username,
      password: btoa(password),
    }
  });
}

export class BizError extends Error {
  code: number;
  constructor(message: string, code: number = DEFAULT_ERROR_CODE) {
    super(message);
    this.name = 'BizError';
    this.code = code;
  }
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    const cookie = parts.pop()!.split(';').shift();
    return cookie;
  }
  return;
}

// const CSRF_KEY = 'x-csrf-token';
const CSRF_KEY = 'Authorization';
instance.interceptors.request.use((config) => {
  const { platform, isYihui, isAigc } = usePlatformStore.getState();

  const token = 'Bearer ' + getToken();
  const headers = new AxiosHeaders({ ...config.headers, 'Authorization': token!, 'X-ISAPI': 1, 'oid': 128, platform});
  config.headers = headers;
  return config;
});

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    if (response.data.code === 401) {
      message.error(response.data.message);
      return;
    } else if (response.data.error_code === 4011) {
      const { removeStoreToken } = useSelectorStore.getState();
      const { setLoginVisible } = useLoginStore.getState();
      removeStoreToken();
      setLoginVisible(true);
      return ;
    }
    // 处理请求异常、业务异常
    if (!response.data) {
      return Promise.reject(new BizError('请求失败，未知原因'));
    }
    return response.data;
  },
  (err) => {
    // 对响应错误做点什么
    return Promise.reject(new BizError(err.message));
  },
);

export default instance;
