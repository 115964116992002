import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Avatar, Breadcrumb, Button, ConfigProvider, Dropdown, Layout, Menu, Modal, Space, theme } from 'antd';
import DashboardMenu from './menu';
import { ZhIcon, EnIcon } from '@/components/Icon/locale'

import enUS from 'antd/locale/en_US';
import zhCN from 'antd/locale/zh_CN';
import customLocale from '@/locales';

import styles from './index.module.scss';
import { MenuOutlined } from '@ant-design/icons';


const me = INIT_STATE.me;

const { Header, Content, Footer } = Layout;

let modalRef;

const App: React.FC = () => {

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [modal, contextHolder] = Modal.useModal();
  console.log("modal: ", modal);
 
  const [locale, setLocale] = useState('zhCN');

  const toggleLocale = () => {
    setLocale(locale === 'zhCN' ? 'enUS' : 'zhCN');
  };

  const localeData = {
    zhCN: {
     ...zhCN,
    },
    enUS: {
     ...enUS,
    },
  }[locale]
  // #000b20
  return (
    <ConfigProvider
      locale={localeData}
      theme={{
        components: {
          Layout: {
            // headerBg: '#000b20',
            // bodyBg: 'block',
            // bodyBg: '#212121',
            bodyBg: '#0d1117',
            // colorBgContainer: '#212121',
          },
          Message: {
            contentBg: '#2a2e33',
            boxShadow:"	0 6px 16px 0 rgba(255, 255, 255, 0.08)"
          },
          FloatButton: {
            colorBgElevated:'#141414'
          },
          Dropdown: {
            colorBgElevated: '#FFFFFF',
            colorText: '#333333',
            controlItemBgHover: 'rgba(98, 87, 255, .8)',
          },
          Slider: {
            trackBg:'rgba(98, 87, 255, .8)',
            handleColor: 'rgba(98, 87, 255, .8)',
            trackHoverBg: 'rgba(98, 87, 255, 1)',
          },
          Input : {
            colorBgContainer: '#25272C'
          },
          InputNumber : {
            colorBgContainer: '#25272C'
          },
          Select : {
            colorBgContainer: '#25272C'
          },
          Upload : {
            // colorBgMask: '#25272C'
          },
          // Input: {
          //   colorBgContainer: '#25272C',
          // }
        },
    
        // algorithm: [theme.defaultAlgorithm, theme.compactAlgorithm],
        algorithm: [theme.darkAlgorithm],
        token: {
          // // Seed Token，影响范围大
          // colorPrimary: '#8a5df6',
          colorPrimary: 'rgba(98, 87, 255, .3)',
          // colorPrimary: '#2f81f7',
          // borderRadius: 2,
          // // 派生变量，影响范围小
          // colorBgContainer: 'white',
        },
      }}
    >
      <Layout className="layout">
        {contextHolder}
        <Content style={{ height: '100vh'}}>
          <Outlet />
        </Content>
      </Layout>
    </ConfigProvider>
  );
};

export default App;