import Cookies from 'js-cookie'

// const TokenKey = 'Course-Admin-Token'
const TokenKey = 'aigc-yiihuu-token'
const OidKey = 'aigc-yiihuu-oid'

export function getToken() {
  // if (Cookies.get(TokenKey) && Cookies.get(AigcToken)) {
  if (Cookies.get(TokenKey)) {
    return Cookies.get(TokenKey)!='null'?Cookies.get(TokenKey):''
  } else {
    // removeToken()
    return ''
  }
}

export function setToken(token) {
  // Cookies.set(AigcToken, token, { domain: '.yiihuu.com' })
  Cookies.set(TokenKey, token? token: '')
}

export function removeToken() {
  // Cookies.remove(AigcToken, { domain: '.yiihuu.com' })
  Cookies.remove(TokenKey)
}


export function getOid() {
  // if (Cookies.get(TokenKey) && Cookies.get(AigcToken)) {
  if (Cookies.get(OidKey)) {
    return Cookies.get(OidKey)!='null'?Cookies.get(OidKey):''
  } else {
    // removeToken()
    return ''
  }
}

export function setOid(oid) {
  // Cookies.set(AigcToken, token, { domain: '.yiihuu.com' })
  Cookies.set(OidKey, oid? oid: '')
}

export function removeOid() {
  // Cookies.remove(AigcToken, { domain: '.yiihuu.com' })
  Cookies.remove(OidKey)
}


// 59 分钟
var inFifteenMinutes = new Date(new Date().getTime() + 1 * 60 * 1000);
export function getOSSInfo() {
  // if (Cookies.get(TokenKey) && Cookies.get(AigcToken)) {
  if (Cookies.get('OSSInfo')) {
    return Cookies.get('OSSInfo')?JSON.parse(Cookies.get('OSSInfo')):'';
  } else {
    return ''
  }
}

export function setOSSInfo(info) {
  // Cookies.set(AigcToken, token, { domain: '.yiihuu.com' })
  Cookies.set("OSSInfo", JSON.stringify(info), {
    expires: inFifteenMinutes
  })
}


export function getLoginActiveInfo() {
  // if (Cookies.get(TokenKey) && Cookies.get(AigcToken)) {
  if (Cookies.get('LoginActiveInfo')) {
    return Cookies.get('LoginActiveInfo')?JSON.parse(Cookies.get('LoginActiveInfo')):'';
  } else {
    return ''
  }
}

export function setLoginActiveInfo(info) {
  // Cookies.set(AigcToken, token, { domain: '.yiihuu.com' })
  Cookies.set("LoginActiveInfo", JSON.stringify(info) /** , { expires: inFifteenMinutes} */);
}

