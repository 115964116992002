import http from '@/utils/http';
import { BatchStoreSendInter, LoginReqInter, LoginResInter, UserInfoResInter, BaseResponse } from "@/types/common/request"
interface ReqType {
  [key: string]: unknown
}
export async function batchImgStore(data: BatchStoreSendInter) {
  const response = await http.post<BaseResponse> (`/api/student_works/aigc_batch_imgs_store`, data);
  return response;
}

export async function fetchUserLogin(data: LoginReqInter) {
  const response = await http.post<LoginResInter> (`/api/user/login/`, data);
  return response;
}

// 删除图库图片
export async function fetchImgDelete(data: ReqType) {
  const response = await http.post(`/api/aigc/batch_img_delete`, data);
  return response;
}


export async function fetchUserInfo() {
  const response = await http.get<UserInfoResInter> (`/api/user/info/`);
  return response;
}



// /api/aigc/batch_img_index?page=1&limit=10&type=0&is_collect=0
export async function fetchBatchImgIndex(data) {
  const response = await http.get(`/api/aigc/batch_img_index`,{params:data});
  return response;
}

// 模型分类
export async function fetchSortIndex(data) {
  const response = await http.get(`/api/aigc/sort/index`,{params:data});
  return response;
}
// 模型列表
export async function fetchAigcModel(data) {
  const response = await http.get(`/api/aigc/model`,{params:data, headers:{
    oid:'128'
  }});
  return response;
}


// 检验是否需要验证码
export function getIsCode(admin_account) {
  return http.get('api/Login/getIsCode', {
    params: { admin_account }
  })
}

// 获取图形验证码
export function getLoginCkey(data?) {
  return http.get('api/captcha')
}
// 获取头部信息等
export function headerInfo() {
  return http.get('/api/org/')
}

// export async function listSDModels(): Promise<unknown[]|undefined> {
//   const sdBaseUrl = getSDBaseAPI();
//   if (!sdBaseUrl) {
//     return;
//   }
//   const response = await request.get(`${sdBaseUrl}/sdapi/v1/sd-models`);
//   return response;
// }

// export async function listLoras(): Promise<unknown[]|undefined> {
//   const sdBaseUrl = getSDBaseAPI();
//   if (!sdBaseUrl) {
//     return;
//   }
//   const response = await request.get(`${sdBaseUrl}/sdapi/v1/loras`);
//   return response;
// }

// export async function listSDVAEs(): Promise<unknown[]|undefined> {
//   const sdBaseUrl = getSDBaseAPI();
//   if (!sdBaseUrl) {
//     return;
//   }
//   const response = await request.get(`${sdBaseUrl}/sdapi/v1/sd-vae`);
//   return response;
// }

// export async function txt2img(params: Record<string, unknown>): Promise<unknown> {
//   const sdBaseUrl = getSDBaseAPI();
//   if (!sdBaseUrl) {
//     return;
//   }
//   const response = await request.post(`${sdBaseUrl}/sdapi/v1/txt2img`, {
//     ...params
//   });
//   return response;
// }

