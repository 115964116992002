// 引入FingerprintJS库
// import FingerprintJS from '@fingerprintjs/fingerprintjs';

// 防抖函数
export function debounce(func: Function, wait: number = 250) {
  let timeout: NodeJS.Timeout;
  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

// 节流函数
export function throttle(func: Function, wait: number = 250) {
  let inThrottle: boolean;
  return function executedFunction(...args: any[]) {
    if (!inThrottle) {
      func(...args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), wait);
    }
  };
}

// 两个版本号比较
export function compareVersion(version1: string, version2: string) {
  let v1 = version1.split(".");
  let v2 = version2.split(".");
  const len = Math.max(v1.length, v2.length);

  while (v1.length < len) {
    v1.push("0");
  }
  while (v2.length < len) {
    v2.push("0");
  }

  for (let i = 0; i < len; i++) {
    const num1 = parseInt(v1[i]);
    const num2 = parseInt(v2[i]);

    if (num1 > num2) {
      return 1;
    } else if (num1 < num2) {
      return -1;
    }
  }

  return 0;
}

// 获取设备平台
export const getDevicePlatform = () => {
  let ua = navigator.userAgent;
  let isWindowsPhone = /(?:Windows Phone)/.test(ua);
  let isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone;
  let isAndroid = /(?:Android)/.test(ua);
  let isFireFox = /(?:Firefox)/.test(ua);
  let isChrome = /(?:Chrome|CriOS)/.test(ua);
  let isWeChat = /(?:MicroMessenger)/i.test(ua);
  let isTablet =
    /(?:iPad|PlayBook)/.test(ua) ||
    (isAndroid && !/(?:Mobile)/.test(ua)) ||
    (isFireFox && /(?:Tablet)/.test(ua));
  let isPhone = /(?:iPhone)/.test(ua) && !isTablet;
  let isPc = !isPhone && !isAndroid && !isSymbian;

  return {
    // 是否平板
    isTablet: isTablet,
    // ios
    isPhone: isPhone,
    // android
    isAndroid: isAndroid,
    // pc
    isPc: isPc,
    isWeChat,
  };
};

// 随机范围内的数字
export function randomNumber(max: number, min: number = 0) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// 加载本地图片
export const requireImg = (imgPath: string) => {
  return new URL(`/src/assets/${imgPath}`, import.meta.url).href;
};

export const toService = () => {
  window.open("/chatlink.html");
};

// 复制
export function copy(data: string) {
  return new Promise((resolve: Function, reject: Function) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    } else {
      try {
        let textarea = document.createElement("textarea");
        document.body.appendChild(textarea);
        textarea.value = data;
        textarea.select();
        document.execCommand("copy");
        textarea.remove();
        resolve();
      } catch (err) {
        reject(err);
      }
    }
  });
}

// // 异步函数获取唯一标识
// export async function getDeviceUniqueId() {
//   let uniqueId = '';
//   try {
//     // 尝试使用FingerprintJS获取唯一标识
//     const fp = await FingerprintJS.load();
//     const result = await fp.get();
//     uniqueId = result.visitorId;
//   } catch (e) {
//     console.error('FingerprintJS failed to generate unique ID:', e);
//     // 如果FingerprintJS失败，尝试使用用户代理信息
//     uniqueId = btoa(navigator.userAgent);
//   }
//   // 增强唯一性，使用Web API
//   const touchPoints = navigator.maxTouchPoints || 0;
//   const hardwareConcurrency = navigator.hardwareConcurrency || 0;
//   // 将所有信息拼接起来，增强唯一性
//   uniqueId += `_${touchPoints}_${hardwareConcurrency}`;
//   // 返回最终的唯一标识
//   return uniqueId;
// }

// // 設置網站ico和標題
// export function setAppInfo(newIconUrl: string, name: string) {
//   // console.log(`12332`, newIconUrl, name);
//   // 创建新的 link 标签
//   var newLink = document.createElement("link");
//   newLink.rel = "icon";
//   newLink.type = "image/x-icon";
//   newLink.href = newIconUrl;

//   // 获取并移除原有的 favicon link（如果有多个，可能需要更精确的选择器）
//   var oldLinks = document.querySelectorAll('link[rel="icon"]');
//   for (var i = 0; i < oldLinks.length; i++) {
//     oldLinks[i].parentNode.removeChild(oldLinks[i]);
//   }

//   // 将新创建的 link 插入到 head 中
//   document.head.appendChild(newLink);
//   document.title = name;
// }
